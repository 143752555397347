let config = {
    buildNumber: "2432",
    cachedAPI: "https://api-cache.monumentalsportsnetwork.com",
    apiBaseUrl:"https://api.monumentalsportsnetwork.com",
    apiKey:  "eDDgMPynXA8W2YeB5b0qe9KtnsuKP96W8aBWQCEF",
    analyticsBaseUrl:"https://analytics-api.monumentalsportsnetwork.com/user-beacon",
    sseBaseUrl: "https://event.monumentalsportsnetwork.com",
    wssBaseUrl: "wss://ws.monumentalsportsnetwork.com",
    appCMSBaseUrl: "https://appcms.monumentalsportsnetwork.com",
    buildAssetsUrl: "https://v-msnuat.monumentalsportsnetwork.com",
    toolsUrl: "https://cms.monumentalsportsnetwork.com",
    templateBuilderUrl: "https://template-builder.monumentalsportsnetwork.com",
    internalEmbedBaseURL: "https://api-internal.monumentalsportsnetwork.com",
    paymentWSUrl: "wss://ws.monumentalsportsnetwork.com",
    filesProxy: {
        directPaths: [
          '/apple-app-site-association',
          '/.well-known/apple-app-site-association',
          '/.well-known/assetlinks.json',
          '/robots.txt',
          '/ads.txt',
          '/app-ads.txt',
          '/sitemap1.xml',
          '/sitemap2.xml',
          '/sitemap3.xml',
          '/sitemap4.xml',
          '/sitemap5.xml',
          '/sitemap6.xml',
          '/sitemap7.xml',
          '/sitemap8.xml',
          '/sitemap9.xml',
          '/sitemap10.xml',
          '/offline.html',
          '/serviceWorker.js',
          '/sw.js'
        ]
      },
    env:"localhost"
   }
   module.exports.config = config;
